// show error messages under the input groups
.input-group.is-invalid {
  .input-group-text {
    border-color: #FA5252;
  }
}

.form-control.is-invalid:focus {
  border-color: #FA5252;
}

/* otherwice dropdowns (popper.js) create horizontal scrolls after toggling */
.dropdown-menu:not(.show){
  display: none !important;
}

/* react-querybuilder */
.with-bootstrap {
  .queryBuilder {
    width: 100%;
    >.ruleGroup {
      margin: 0 !important;
    }
  }

  .btn {
    z-index: inherit !important;
  }

  .form-control,
  .form-select {
    display: inline-block;
    width: auto;
  }

  .form-select {
    padding-right: 2rem;
  }

  .ruleGroup {
    background-color: rgba(32, 37, 54, 0.05);
    padding: 0.5rem;
    margin: 0.5rem;
    border-radius: 4px;
  }

  .ruleGroup-header {
    margin-top: 0.1rem;
    margin-bottom: 0.1rem;
  }

  .ruleGroup-body {
    margin-top: 0.1rem;
    margin-bottom: 0.1rem;
  }

  .form-select, .btn {
    margin: 0.1rem;
  }

  .form-control {
    border-radius: 6px;
    padding-left: 0.4rem !important;
    padding-right: 0.4rem !important;
    margin: 0.1rem;

    label {
      padding-right: 0.4rem;
    }
  }

  input[type=checkbox].rule-value{
    appearance: auto !important;
    vertical-align: bottom;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }

  .rule-value {
    input[type=radio]{
      vertical-align: middle !important;
      margin-bottom: 0.15rem !important;
      margin-right: 0.1rem !important;
    }  
  }

  font-size: 16px;
}


.list-underline-separated > :not(:first-child) {
  border-top: solid 0.5px lightgray;
  margin-top: 3px;
}